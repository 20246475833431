/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h3: "h3",
    ul: "ul",
    li: "li",
    div: "div",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/spotmop-1024x647.jpg",
    alt: ""
  }), "Con una Raspberry Pi y unos altavoces de PC nos podemos montar nuestro Jukebox para el siglo XXI y de paso olvidarnos de Spotify y Youtube rescatando nuestra antigua colección de música. La idea es no solo poder reproducir tu colección de música cuando lo desees, si no que en el caso de invitar a los amigos a casa, puedan seleccionar las canciones que quieran poner en cualquier momento, veremos hasta que punto podemos llegar, vamos a ponernos."), "\n", React.createElement(_components.h3, null, "Necesitamos"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Raspberry Pi con Raspbian: No importa el modelo, yo he probado con la 1 y funciona perfectamente. También podéis intentarlo en otras plataformas con cualquier distro basada en Debian."), "\n", React.createElement(_components.li, null, "Disco duro USB: Donde almacenar nuestra colección de música. Presupondré que ya tenemos configurado el disco para montarse automáticamente al encender la Raspberry, hay montones de tutoriales sobre como hacerlo."), "\n", React.createElement(_components.li, null, "Altavoces: En este caso voy a usar unos sencillos altavoces de PC con entrada mini jack."), "\n"), "\n", React.createElement(_components.h3, null, "Instalación"), "\n", React.createElement(_components.p, null, "Añadimos el repositorio ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">wget -q -O - https://apt.mopidy.com/mopidy.gpg | sudo apt-key add - sudo wget -q -O /etc/apt/sources.list.d/mopidy.list https://apt.mopidy.com/jessie.list sudo apt-get update</code>"
    }
  }), " Instalamos mopidy, el servidor de música, con algunas extensiones para que no esté demasiado pelado. ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo apt install mopidy mopidy-local-sqlite mopidy-tunein mopidy-podcast mopidy-youtube mopidy-internetarchive gstreamer1.0-plugins-bad</code>"
    }
  }), " Además vamos a instalar una interfaz web muy pulida Spotmop, pero esto ya lo tenemos que instalar mediante el sistema de paquetería de Python, Pip. ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo apt install python-pip sudo pip install Mopidy-Spotmop</code>"
    }
  })), "\n", React.createElement(_components.h3, null, "Configuración"), "\n", React.createElement(_components.p, null, "Para correr como servicio mopidy vamos a proceder a reconfigurarlo, para cambiar la opción por defecto:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo dpkg-reconfigure mopidy</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y nos preguntará si queremos iniciar en el arranque, le decimos que sí y pasamos a editar la configuración."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo nano /etc/mopidy/mopidy.conf</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Mi archivo de configuración ha quedado de la siguiente forma: ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">[core] cache_dir = /var/cache/mopidy config_dir = /etc/mopidy data_dir = /var/lib/mopidy [logging] config_file = /etc/mopidy/logging.conf debug_file = /var/log/mopidy/mopidy-debug.log [local] media_dir = /media/hdd/público/litox/files/Música library = sqlite scan_flush_threshold = 100 [m3u] playlists_dir = /var/lib/mopidy/playlists [mpd] enabled = true hostname = :: port = 6600 password = 9876 max_connections = 20 connection_timeout = 60 zeroconf = Mopidy MPD server on $hostname command_blacklist = listall,listallinfo default_playlist_scheme = m3u [http] enabled = true hostname = :: port = 6680 static_dir = zeroconf = Mopidy HTTP server on $hostname [spotmod] enabled = true [tunein] timeout = 5000</code>"
    }
  }), " Como podéis ver he cambiado el media_dir por mi directorio de música en owncloud, de esta forma lo tengo además todo sincronizado entre dispositivos. Obviamente debemos dar permisos de lectura y escritura al usuario al usuario mopidy en esta carpeta, por ejemplo añadiéndolo al mismo grupo al que pertenece el servidor, en mi caso www-data."), "\n", React.createElement(_components.h3, null, "A reproducir"), "\n", React.createElement(_components.p, null, "Una vez cargada la música en este directorio y reiniciado vamos a escanear la música que tenemos."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo mopidyctl local scan</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y cuando termine vamos a Spotmop para empezar a reproducir música. En el navegador abrimos ", React.createElement(_components.a, {
    href: "http://raspberrypi.local:6680/spotmop/"
  }, "http://raspberrypi.local:6680/spotmop/"), " y en el menú local podremos buscar álbum o autor para empezar a reproducir."), "\n", React.createElement(_components.p, null, "Desde cualquier dispositivo ", React.createElement(_components.strong, null, "Android"), " podremos hacer lo mismo instalándonos la aplicación Mopidy Mobile. No he conseguido ", React.createElement(_components.a, {
    href: "https://github.com/mopidy/mopidy/issues/39"
  }, "que se anuncie el servicio en la red mediante Avahi"), ", por lo que me ha tocado poner la dirección IP a mano con el puerto correspondiente para acceder. Una lástima, porque el objetivo era que cualquiera al que yo diera acceso a mi red, pudiera rápidamente descargar la aplicación y empezar a su alma selecter, espero que se solucione en el futuro este aspecto."), "\n", React.createElement(_components.p, null, "Fuentes:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.inpimation.com/installing-mopidy-raspberry-pi-2/"
  }, "https://www.inpimation.com/installing-mopidy-raspberry-pi-2/")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.mopidy.com/en/latest/service/"
  }, "https://docs.mopidy.com/en/latest/service/")), "\n", React.createElement(_components.p, null, "Actualización:"), "\n", React.createElement(_components.p, null, "Ahora he empezado a utilizar un cliente para GNU/Linux, Cantata, la configuración es sencilla, como host raspberrypi.local, el puerto MPD que si está por defecto es el 6600 como en el mío y la contraseña que en mi caso es 9876. Y así tendremos algo parecido Spotmop pero en versión nativa."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
